<template>
  <div class="landingpage-body" v-show="displayUI">
    <div @click="gotToTop" class="btn-goto-top" v-bind:class="{ showGoToTopBtn: scrolled }">
      <i class="fas fa-angle-up" aria-hidden="true"></i>
    </div>

    <a :href="whatsappLink" target="_blank" class="whatsapp-float">
      <img src="/assets/images/watsapp_icon.png" alt="WhatsApp Chat" class="whatsapp-icon">
    </a>

    <app-header :scrolled="scrolled"></app-header>
    <div class="landing-page-content">
      <transition-page>
        <router-view />
      </transition-page>
      <chatModal></chatModal>
    </div>
    <div class="landing-page-footer"></div>
  </div>
</template>

<script>
import TransitionPage from "../../transitions/TransitionPage.vue";
import Header from "./appHeader.vue";
import Footer from "./appFooter.vue";
import chatModal from "../../components/chatModal";

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    TransitionPage,
    chatModal,
  },
  metaInfo() {
    return {
      title: "",
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      meta: [
        { property: "og:title", content: "KIPS VIRTUAL" },
        { property: "og:site_name", content: "Kips VIRTUAL" },
        { property: "og:type", content: "website" },
        { property: "og:url", content: process.env.VUE_APP_URL },
        { property: "og:description", content: "One Platform For All Your Learning Needs" },
        { property: "og:image", content: `${process.env.VUE_APP_URL}/assets/images/cover-img.jpg` },
        { property: "og:image:type", content: "image/jpg" },
      ],
    };
  },
  data() {
    return {
      scrolled: false,
      displayUI: true,
      whatsappLink: 'https://wa.me/923104449808'
    };
  },

  mounted() {
    this.displayUI = true;
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    gotToTop() {
      window.scrollTo(0, 0);
      window.location.hash = "";
    },
  },
  watch: {
    "$route.query"() {
      if (this.$route.hash) {
        var elm = this.$route.hash.slice(1);
        this.scrollToElement(elm);
      }
    },
  },
  created() {
    this.displayUI = true;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.whatsapp-float {
  position: fixed;
  bottom: 140px;
  right: 10px;
  z-index: 1000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.whatsapp-icon {
  width: 95px;
  height: 95px;
  transition: transform 0.3s ease;
}

.whatsapp-float:hover .whatsapp-icon {
  transform: scale(1.1);
}
</style>